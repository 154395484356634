<template>
  <v-list class="faq-category-list">
    <v-list-item
      v-for="item in content"
      :key="item.id"
      class="faq-category-list-item"
    >
      <router-link
        class="faq-category-list-item-link"
        :to="{ name: 'FaqList', params: { categoryName: item.slug } }"
        v-html="item.name"
      ></router-link>
    </v-list-item>
  </v-list>
</template>

<script>
import CMSService from "~/service/cmService";
export default {
  name: "FaqCategory",
  data() {
    return {
      content: null
    };
  },
  created() {
    this.fetchPage();
  },
  methods: {
    async fetchPage() {
      let res = await CMSService.getCustomPostByFilters("categoria_faq");
      this.content = res.data;
    }
  }
};
</script>
