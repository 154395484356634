import CMSService from "~/service/cmService";

export default {
  data() {
    return { page: {}, key: 1 };
  },
  computed: {
    content() {
      return this._.get(this.page, "content.rendered");
    },

    validity() {
      return this._.get(this.page, "testo_validit");
    },
    breadcrumb() {
      let path = [];
      path.push({
        link: true,
        text: "Homepage",
        to: "/"
      });
      if (this.page) {
        let pageTitle = this.normalizePageTitle(
          this._.get(this.page, "title.rendered")
        );
        path.push({
          link: true,
          text: pageTitle,
          to: { name: "Page", slug: this.page.slug }
        });
      }
      return path;
    }
  },
  methods: {
    normalizePageTitle(title) {
      let pageTitle = title;
      if (pageTitle && pageTitle.indexOf("&#038;") > 0) {
        pageTitle = pageTitle.replace("&#038;", "&");
      }
      return pageTitle;
    },
    async fetchPage(pageName) {
      let data = await CMSService.getPage(pageName);

      if (data.length > 0) {
        this.page = data[0];
        this.key++;
      }
    }
  },
  metaInfo() {
    return {
      title: this.normalizePageTitle(this._.get(this.page, "meta.seo_title")),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this._.get(this.page, "meta.seo_description")
        }
      ]
    };
  }
};
